<template>
  <div>
    <a-row :gutter="16">
      <a-col :span="24">
        <a-form-item label="Nama Cuti">
            <a-input v-model="dataCuti.nama" size="large" />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item label="Tipe">
          <a-select size="large" class="w-100" :value="dataCuti.tipe" @change="(e) => handleChange(e, 'tipe')">
            <a-select-option v-for="(item, index) in daftarTipeCuti" :key="index" :value="item.nama">
              {{item.nama}}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item label="Regulasi Cuti">
          <a-select size="large" class="w-100" :value="dataCuti.regulasi" @change="(e) => handleChange(e, 'regulasi')">
            <a-select-option v-for="(item, index) in daftarRegulasi" :key="index" :value="item.nama">
              {{item.nama}}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="Durasi Cuti Maksimal">
          <a-input-group compact>
            <a-input-number class="w-50" size="large" :min="1" :step="1" :max="100" v-model="dataCuti.durasi_maksimal"
              @change="(e) => handleChange(e, 'all', 'durasi_maksimal')" />
              <a-select class="w-50" size="large" :value="dataCuti.tipe_durasi" @change="(e) => handleChange(e, 'tipe_durasi')">
              <a-select-option value="hari">
                Hari
              </a-select-option>
              <a-select-option value="bulan">
                Bulan
              </a-select-option>
            </a-select>
          </a-input-group>
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="Deskripsi">
          <a-textarea
            v-model="dataCuti.deskripsi"
            :auto-size="{ minRows: 5, maxRows: 7 }"
          />
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
const daftarTipeCuti = [
  {
    nama: 'cuti',
  },
  {
    nama: 'lain-lain',
  },
]
const daftarRegulasi = [
  {
    nama: 'Dibayar',
  },
  {
    nama: 'Tidak Dibayar',
  },
]
export default {
  props: {
    dataCuti: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      daftarTipeCuti,
      daftarRegulasi,
      endOpen: false,
    }
  },
  methods: {
    disabledStartDate(startValue) {
      const endValue = this.dataCuti.tanggal_akhir
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate(endValue) {
      const startValue = this.dataCuti.tanggal_awal
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() >= endValue.valueOf()
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    handleChange(value, column) {
      const payload = { value, column }
      this.$emit('handle-change', payload)
    },
  },
}
</script>

<style>

</style>
